<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import FormBase from '@/components/mixins/FormBase'

import FormTabs from '@/components/form/FormTabs.vue'
import FormTab from '@/components/form/FormTab.vue'
import GeneralTab from './tabs/General.vue'
import HistoryTab from './tabs/History.vue'
import RefundTab from './tabs/Refund.vue'
import InvoicesTab from './tabs/Invoices.vue'

export default {
    components: {
        FormTabs,
        FormTab,
        GeneralTab,
        HistoryTab,
        RefundTab,
        InvoicesTab,
    },

    mixins: [FormBase],

    data() {
        return {
            api: RestApiType.ORDERS,
            mapper: MapperType.ORDER_FORM,
            form: { deliveryAddressDetails: {} },
            entity: 'labels.order',
            listRoute: routeType.ORDER_LIST,
            isValid: false,
        }
    },
    computed: {
        tabs() {
            return [
                { name: 'general', title: this.$t('labels.order_details') },
                {
                    name: 'history',
                    title: this.$t('labels.order_history'),
                },
                {
                    name: 'refund',
                    title: this.$t('labels.order_refund'),
                },
                {
                    name: 'invoices',
                    title: this.$t('labels.order_invoices'),
                    disabled: !this.form.id,
                },
            ]
        },
        activeTab: {
            get() {
                return parseInt(this.$route.query.tab) || 0
            },
            set(val) {
                this.$router.replace({ query: { tab: val } })
            },
        },
        title() {
            return this.form?.identifier ? this.form.identifier : null
        },
        deliveryJobId() {
            return this.form?.deliveryJob ? this.form.deliveryJob.id : null
        },
    },

    methods: {
        baseFormValid(value) {
            this.isValid = value
        },
        reloadOrder() {
            this.$refs.form.load()
        },
    },
}
</script>

<template>
    <base-form
        ref="form"
        v-model="form"
        :api="api"
        :mapper="mapper"
        :entity-id="entityId"
        :title="title"
        :disabled="disabled"
        :disable-save-button="activeTab == 1"
        :disable-delete-button="true"
        @valid="baseFormValid($event)"
        @updated="updated"
        @cancelled="cancelled"
    >
        <template v-if="form">
            <form-tabs v-model="activeTab" class="pb-16">
                <form-tab
                    v-for="tab in tabs"
                    :key="tab.name"
                    :tab-errors="tabErrors"
                    :name="tab.name"
                    :title="tab.title"
                    :disabled="tab.disabled"
                ></form-tab>

                <v-tabs-items v-model="activeTab">
                    <general-tab
                        v-model="form"
                        :api="api"
                        :mapper="mapper"
                        :is-valid="isValid"
                        @tabError="tabErrorsContainer = $event"
                    ></general-tab>
                    <history-tab
                        v-model="form"
                        @tabError="tabErrorsContainer = $event"
                    ></history-tab>
                    <refund-tab
                        :order="form"
                        :tab-active="activeTab == 2"
                        @tabError="tabErrorsContainer = $event"
                        @refund="reloadOrder"
                    ></refund-tab>
                    <invoices-tab
                        v-model="form"
                        @tabError="tabErrorsContainer = $event"
                    ></invoices-tab>
                </v-tabs-items>
            </form-tabs>
        </template>
    </base-form>
</template>
