<template>
    <v-tab-item
        key="invoice_tab"
        :transition="false"
        :reverse-transition="false"
    >
        <form-panel
            v-if="model.invoiceData"
            :title="$t('orders.invoice_settings')"
        >
            <div>
                <checkbox-input
                    v-model="supplier"
                    class="d-inline-block"
                    label="orders.invoice.supplier"
                ></checkbox-input>
            </div>
            <v-row v-show="supplier">
                <v-col cols="4">
                    <text-input
                        v-model="model.invoiceData.supplierInvoiceInvoiceNumber"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(supplier, { space: true })
                        "
                        name="supplierInvoiceInvoiceNumber"
                        label="orders.invoice.number"
                        :error-messages="
                            errors.collect('supplierInvoiceInvoiceNumber')
                        "
                        :data-vv-as="$t('orders.invoice.number')"
                    ></text-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="model.invoiceData.supplierInvoiceNetValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(supplier, {
                                decimal: {
                                    decimals: 2,
                                },
                            })
                        "
                        name="supplierInvoiceNetValue"
                        label="orders.invoice.net"
                        :error-messages="
                            errors.collect('supplierInvoiceNetValue')
                        "
                        :data-vv-as="$t('orders.invoice.net')"
                    ></currency-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="model.invoiceData.supplierInvoiceVatValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(supplier, {
                                decimal: {
                                    decimals: 2,
                                },
                            })
                        "
                        name="supplierInvoiceVatValue"
                        label="orders.invoice.vat"
                        :error-messages="
                            errors.collect('supplierInvoiceVatValue')
                        "
                        :data-vv-as="$t('orders.invoice.vat')"
                    ></currency-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="model.invoiceData.supplierInvoiceGrossValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(supplier, {
                                decimal: {
                                    decimals: 2,
                                },
                                'invoice-sum': [
                                    model.invoiceData.supplierInvoiceNetValue,
                                    model.invoiceData.supplierInvoiceVatValue,
                                ],
                            })
                        "
                        name="supplierInvoiceGrossValue"
                        label="orders.invoice.gross"
                        :error-messages="
                            errors.collect('supplierInvoiceGrossValue')
                        "
                        :data-vv-as="$t('orders.invoice.gross')"
                    ></currency-input>
                </v-col>
            </v-row>

            <!-- Additional supplier -->

            <v-row
                v-for="(invoice, index) in model.invoiceData
                    .additionalSupplierInvoices"
                v-show="supplier"
                :key="index"
            >
                <v-col cols="4">
                    <text-input
                        v-model="invoice.InvoiceNumber"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(invoice, { space: true })
                        "
                        :name="`model.invoiceData.additionalSupplierInvoices[${index}].InvoiceNumber`"
                        label="orders.invoice.number"
                        :error-messages="
                            errors.collect(
                                `model.invoiceData.additionalSupplierInvoices[${index}].InvoiceNumber`
                            )
                        "
                        :data-vv-as="$t('orders.invoice.number')"
                    ></text-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="invoice.NetValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(invoice, {
                                decimal: { decimals: 2 },
                            })
                        "
                        :name="`model.invoiceData.additionalSupplierInvoices[${index}].NetValue`"
                        label="orders.invoice.net"
                        :error-messages="
                            errors.collect(
                                `model.invoiceData.additionalSupplierInvoices[${index}].NetValue`
                            )
                        "
                        :data-vv-as="$t('orders.invoice.net')"
                    ></currency-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="invoice.VatValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(invoice, {
                                decimal: { decimals: 2 },
                            })
                        "
                        :name="`model.invoiceData.additionalSupplierInvoices[${index}].VatValue`"
                        label="orders.invoice.vat"
                        :error-messages="
                            errors.collect(
                                `model.invoiceData.additionalSupplierInvoices[${index}].VatValue`
                            )
                        "
                        :data-vv-as="$t('orders.invoice.vat')"
                    ></currency-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="invoice.GrossValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(invoice, {
                                decimal: { decimals: 2 },
                                'invoice-sum': [
                                    invoice.NetValue,
                                    invoice.VatValue,
                                ],
                            })
                        "
                        :name="`model.invoiceData.additionalSupplierInvoices[${index}].GrossValue`"
                        label="orders.invoice.gross"
                        :error-messages="
                            errors.collect(
                                `model.invoiceData.additionalSupplierInvoices[${index}].GrossValue`
                            )
                        "
                        :data-vv-as="$t('orders.invoice.gross')"
                    ></currency-input>
                </v-col>
                <v-col cols="2">
                    <v-btn
                        class="mt-5"
                        color="error"
                        icon
                        small
                        @click="
                            removeAdditionalSupplierInvoicesInvoiceInvoice(
                                index
                            )
                        "
                    >
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row v-if="supplier">
                <v-col>
                    <v-btn
                        color="primary"
                        small
                        @click="addAdditionalSupplierInvoicesInvoice"
                    >
                        <v-icon left>add</v-icon
                        >{{ $t('orders.invoice.add_more') }}
                    </v-btn>
                </v-col>
            </v-row>

            <!-- Commission -->

            <div>
                <checkbox-input
                    v-model="commission"
                    class="d-inline-block"
                    label="orders.invoice.commission"
                ></checkbox-input>
            </div>
            <v-row v-show="commission">
                <v-col cols="4">
                    <text-input
                        v-model="
                            model.invoiceData.commissionInvoiceInvoiceNumber
                        "
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(commission, { space: true })
                        "
                        name="commissionInvoiceInvoiceNumber"
                        label="orders.invoice.number"
                        :error-messages="
                            errors.collect('commissionInvoiceInvoiceNumber')
                        "
                        :data-vv-as="$t('orders.invoice.number')"
                    ></text-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="model.invoiceData.commissionInvoiceNetValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(commission, {
                                decimal: {
                                    decimals: 2,
                                },
                            })
                        "
                        name="commissionInvoiceNetValue"
                        label="orders.invoice.net"
                        :error-messages="
                            errors.collect('commissionInvoiceNetValue')
                        "
                        :data-vv-as="$t('orders.invoice.net')"
                    ></currency-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="model.invoiceData.commissionInvoiceVatValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(commission, {
                                decimal: {
                                    decimals: 2,
                                },
                            })
                        "
                        name="commissionInvoiceVatValue"
                        label="orders.invoice.vat"
                        :error-messages="
                            errors.collect('commissionInvoiceVatValue')
                        "
                        :data-vv-as="$t('orders.invoice.vat')"
                    ></currency-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="model.invoiceData.commissionInvoiceGrossValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(commission, {
                                decimal: {
                                    decimals: 2,
                                },
                                'invoice-sum': [
                                    model.invoiceData.commissionInvoiceNetValue,
                                    model.invoiceData.commissionInvoiceVatValue,
                                ],
                            })
                        "
                        name="commissionInvoiceGrossValue"
                        label="orders.invoice.gross"
                        :error-messages="
                            errors.collect('commissionInvoiceGrossValue')
                        "
                        :data-vv-as="$t('orders.invoice.gross')"
                    ></currency-input>
                </v-col>
            </v-row>

            <!-- Additional commission -->

            <v-row
                v-for="(invoice, index) in model.invoiceData
                    .additionalCommissionInvoices"
                v-show="commission"
                :key="index"
            >
                <v-col cols="4">
                    <text-input
                        v-model="invoice.InvoiceNumber"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(invoice, { space: true })
                        "
                        :name="`model.invoiceData.additionalCommissionInvoices[${index}].InvoiceNumber`"
                        label="orders.invoice.number"
                        :error-messages="
                            errors.collect(
                                `model.invoiceData.additionalCommissionInvoices[${index}].InvoiceNumber`
                            )
                        "
                        :data-vv-as="$t('orders.invoice.number')"
                    ></text-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="invoice.NetValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(invoice, {
                                decimal: {
                                    decimals: 2,
                                },
                            })
                        "
                        :name="`model.invoiceData.additionalCommissionInvoices[${index}].NetValue`"
                        label="orders.invoice.net"
                        :error-messages="
                            errors.collect(
                                `model.invoiceData.additionalCommissionInvoices[${index}].NetValue`
                            )
                        "
                        :data-vv-as="$t('orders.invoice.net')"
                    ></currency-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="invoice.VatValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(invoice, {
                                decimal: {
                                    decimals: 2,
                                },
                            })
                        "
                        :name="`model.invoiceData.additionalCommissionInvoices[${index}].VatValue`"
                        label="orders.invoice.vat"
                        :error-messages="
                            errors.collect(
                                `model.invoiceData.additionalCommissionInvoices[${index}].VatValue`
                            )
                        "
                        :data-vv-as="$t('orders.invoice.vat')"
                    ></currency-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="invoice.GrossValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(invoice, {
                                decimal: {
                                    decimals: 2,
                                },
                                'invoice-sum': [
                                    invoice.NetValue,
                                    invoice.VatValue,
                                ],
                            })
                        "
                        :name="`model.invoiceData.additionalCommissionInvoices[${index}].GrossValue`"
                        label="orders.invoice.gross"
                        :error-messages="
                            errors.collect(
                                `model.invoiceData.additionalCommissionInvoices[${index}].GrossValue`
                            )
                        "
                        :data-vv-as="$t('orders.invoice.gross')"
                    ></currency-input>
                </v-col>

                <v-col cols="2">
                    <v-btn
                        class="mt-5"
                        color="error"
                        icon
                        small
                        @click="removeAdditionalCommissionInvoice(index)"
                    >
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row v-if="commission">
                <v-col>
                    <v-btn
                        color="primary"
                        small
                        @click="addAdditionalCommissionInvoice"
                    >
                        <v-icon left>add</v-icon>
                        {{ $t('orders.invoice.add_more') }}
                    </v-btn>
                </v-col>
            </v-row>

            <!-- Sales -->

            <div>
                <checkbox-input
                    v-model="sales"
                    class="d-inline-block"
                    label="orders.invoice.sales"
                ></checkbox-input>
            </div>
            <v-row v-show="sales">
                <v-col cols="4">
                    <text-input
                        v-model="model.invoiceData.salesInvoiceInvoiceNumber"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(sales, { space: true })
                        "
                        name="salesInvoiceInvoiceNumber"
                        label="orders.invoice.number"
                        :error-messages="
                            errors.collect('salesInvoiceInvoiceNumber')
                        "
                        :data-vv-as="$t('orders.invoice.number')"
                    ></text-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="model.invoiceData.salesInvoiceNetValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(sales, {
                                decimal: {
                                    decimals: 2,
                                },
                            })
                        "
                        name="salesInvoiceNetValue"
                        label="orders.invoice.net"
                        :error-messages="errors.collect('salesInvoiceNetValue')"
                        :data-vv-as="$t('orders.invoice.net')"
                    ></currency-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="model.invoiceData.salesInvoiceVatValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(sales, {
                                decimal: {
                                    decimals: 2,
                                },
                            })
                        "
                        name="salesInvoiceVatValue"
                        label="orders.invoice.vat"
                        :error-messages="errors.collect('salesInvoiceVatValue')"
                        :data-vv-as="$t('orders.invoice.vat')"
                    ></currency-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="model.invoiceData.salesInvoiceGrossValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(sales, {
                                decimal: {
                                    decimals: 2,
                                },
                                'invoice-sum': [
                                    model.invoiceData.salesInvoiceNetValue,
                                    model.invoiceData.salesInvoiceVatValue,
                                ],
                            })
                        "
                        name="salesInvoiceGrossValue"
                        label="orders.invoice.gross"
                        :error-messages="
                            errors.collect('salesInvoiceGrossValue')
                        "
                        :data-vv-as="$t('orders.invoice.gross')"
                    ></currency-input>
                </v-col>
            </v-row>

            <!-- Additional sales -->

            <v-row
                v-for="(invoice, index) in model.invoiceData
                    .additionalSalesInvoices"
                v-show="sales"
                :key="index"
            >
                <v-col cols="4">
                    <text-input
                        v-model="invoice.InvoiceNumber"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(invoice, { space: true })
                        "
                        :name="`model.invoiceData.additionalSalesInvoices[${index}].InvoiceNumber`"
                        label="orders.invoice.number"
                        :error-messages="
                            errors.collect(
                                `model.invoiceData.additionalSalesInvoices[${index}].InvoiceNumber`
                            )
                        "
                        :data-vv-as="$t('orders.invoice.number')"
                    ></text-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="invoice.NetValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(invoice, {
                                decimal: {
                                    decimals: 2,
                                },
                            })
                        "
                        :name="`model.invoiceData.additionalSalesInvoices[${index}].NetValue`"
                        label="orders.invoice.net"
                        :error-messages="
                            errors.collect(
                                `model.invoiceData.additionalSalesInvoices[${index}].NetValue`
                            )
                        "
                        :data-vv-as="$t('orders.invoice.net')"
                    ></currency-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="invoice.VatValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(invoice, {
                                decimal: {
                                    decimals: 2,
                                },
                            })
                        "
                        :name="`model.invoiceData.additionalSalesInvoices[${index}].VatValue`"
                        label="orders.invoice.vat"
                        :error-messages="
                            errors.collect(
                                `model.invoiceData.additionalSalesInvoices[${index}].VatValue`
                            )
                        "
                        :data-vv-as="$t('orders.invoice.vat')"
                    ></currency-input>
                </v-col>
                <v-col cols="2">
                    <currency-input
                        v-model="invoice.GrossValue"
                        v-tab-error:invoices
                        v-validate.immediate="
                            rowValidator(invoice, {
                                decimal: {
                                    decimals: 2,
                                },
                                'invoice-sum': [
                                    invoice.NetValue,
                                    invoice.VatValue,
                                ],
                            })
                        "
                        :name="`model.invoiceData.additionalSalesInvoices[${index}].GrossValue`"
                        label="orders.invoice.gross"
                        :error-messages="
                            errors.collect(
                                `model.invoiceData.additionalSalesInvoices[${index}].GrossValue`
                            )
                        "
                        :data-vv-as="$t('orders.invoice.gross')"
                    ></currency-input>
                </v-col>

                <v-col cols="2">
                    <v-btn
                        class="mt-5"
                        color="error"
                        icon
                        small
                        @click="removeAdditionalSalesInvoice(index)"
                    >
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row v-if="sales">
                <v-col>
                    <v-btn
                        color="primary"
                        small
                        @click="addAdditionalSalesInvoice"
                    >
                        <v-icon left>add</v-icon>Add Invoice
                    </v-btn>
                </v-col>
            </v-row>

            <!-- Restaurant paid -->

            <div>
                <checkbox-input
                    v-model="date"
                    class="d-inline-block"
                    label="orders.invoice.paid"
                ></checkbox-input>
            </div>
            <v-row v-show="date">
                <v-col cols="4" class="date-input-space">
                    <date-picker-input
                        v-model="model.invoiceData.supplierPaidDate"
                        v-tab-error:invoices
                        v-validate.immediate="'date_format:yyyy-MM-dd'"
                        name="supplierPaidDate"
                        label="orders.invoice.paid_date"
                        only-date
                        :error-messages="errors.collect('supplierPaidDate')"
                        :data-vv-as="$t('orders.invoice.paid_date')"
                    ></date-picker-input>
                </v-col>
            </v-row>

            <div>
                <checkbox-input
                    v-model="model.invoiceData.requiresSeparateInvoice"
                    class="d-inline-block"
                    label="orders.invoice.requires_separate_invoice"
                ></checkbox-input>
            </div>
        </form-panel>
    </v-tab-item>
</template>

<script>
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'

import FormPanel from '@/components/form/FormPanel'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import TextInput from '@/components/form/Inputs/TextInput'
import CurrencyInput from '@/components/form/Inputs/CurrencyInput'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'

import { DATE_FORMAT } from '@/const/DateTimeFormats'

export default {
    components: {
        FormPanel,
        CheckboxInput,
        TextInput,
        CurrencyInput,
        DatePickerInput,
    },
    mixins: [FormTabMixin, FormInput],
    inject: {
        $validator: 'formValidator',
    },
    computed: {
        supplier: {
            get() {
                return (
                    !!this.model?.invoiceData?.supplierInvoiceInvoiceNumber ||
                    !!this.model?.invoiceData?.supplierInvoiceNetValue ||
                    !!this.model?.invoiceData?.supplierInvoiceVatValue ||
                    !!this.model?.invoiceData?.supplierInvoiceGrossValue
                )
            },
            set(value) {
                if (value) {
                    this.model.invoiceData.supplierInvoiceGrossValue = this.model.priceSumItems
                } else {
                    if (this.model.invoiceData?.supplierInvoiceInvoiceNumber) {
                        this.model.invoiceData.supplierInvoiceInvoiceNumber = null
                    }
                    if (this.model.invoiceData?.supplierInvoiceNetValue) {
                        this.model.invoiceData.supplierInvoiceNetValue = null
                    }
                    if (this.model.invoiceData?.supplierInvoiceVatValue) {
                        this.model.invoiceData.supplierInvoiceVatValue = null
                    }
                    if (this.model.invoiceData?.supplierInvoiceGrossValue) {
                        this.model.invoiceData.supplierInvoiceGrossValue = null
                    }
                    this.model.invoiceData.additionalSupplierInvoices = []
                }
            },
        },
        commission: {
            get() {
                return (
                    !!this.model?.invoiceData?.commissionInvoiceInvoiceNumber ||
                    !!this.model?.invoiceData?.commissionInvoiceNetValue ||
                    !!this.model?.invoiceData?.commissionInvoiceVatValue ||
                    !!this.model?.invoiceData?.commissionInvoiceGrossValue
                )
            },
            set(value) {
                if (value) {
                    this.model.invoiceData.commissionInvoiceGrossValue = this.model.priceSumItems
                } else {
                    if (
                        this.model.invoiceData?.commissionInvoiceInvoiceNumber
                    ) {
                        this.model.invoiceData.commissionInvoiceInvoiceNumber = null
                    }
                    if (this.model.invoiceData?.commissionInvoiceNetValue) {
                        this.model.invoiceData.commissionInvoiceNetValue = null
                    }
                    if (this.model.invoiceData?.commissionInvoiceVatValue) {
                        this.model.invoiceData.commissionInvoiceVatValue = null
                    }
                    if (this.model.invoiceData?.commissionInvoiceGrossValue) {
                        this.model.invoiceData.commissionInvoiceGrossValue = null
                    }
                    this.model.invoiceData.additionalCommissionInvoices = []
                }
            },
        },
        sales: {
            get() {
                return (
                    !!this.model?.invoiceData?.salesInvoiceInvoiceNumber ||
                    !!this.model?.invoiceData?.salesInvoiceNetValue ||
                    !!this.model?.invoiceData?.salesInvoiceVatValue ||
                    !!this.model?.invoiceData?.salesInvoiceGrossValue
                )
            },
            set(value) {
                if (value) {
                    this.model.invoiceData.salesInvoiceGrossValue = this.model.priceSumItems
                } else {
                    if (this.model.invoiceData?.salesInvoiceInvoiceNumber) {
                        this.model.invoiceData.salesInvoiceInvoiceNumber = null
                    }
                    if (this.model.invoiceData?.salesInvoiceNetValue) {
                        this.model.invoiceData.salesInvoiceNetValue = null
                    }
                    if (this.model.invoiceData?.salesInvoiceVatValue) {
                        this.model.invoiceData.salesInvoiceVatValue = null
                    }
                    if (this.model.invoiceData?.salesInvoiceGrossValue) {
                        this.model.invoiceData.salesInvoiceGrossValue = null
                    }
                    this.model.invoiceData.additionalSalesInvoices = []
                }
            },
        },
        date: {
            get() {
                return !!this.model?.invoiceData?.supplierPaidDate
            },
            set(value) {
                this.model.invoiceData.supplierPaidDate = value
                    ? this.$date().format(DATE_FORMAT)
                    : null
            },
        },
    },
    methods: {
        rowValidator(invoice, extraValidators = {}) {
            return {
                required:
                    !!invoice.InvoiceNumber ||
                    !!invoice.NetValue ||
                    !!invoice.VatValue ||
                    !!invoice.GrossValue,
                ...extraValidators,
            }
        },
        addAdditionalSupplierInvoicesInvoice() {
            this.model.invoiceData.additionalSupplierInvoices.push({
                InvoiceNumber: '',
                NetValue: null,
                VatValue: null,
                GrossValue: null,
            })
        },
        removeAdditionalSupplierInvoicesInvoiceInvoice(index) {
            this.model.invoiceData.additionalSupplierInvoices.splice(index, 1)
        },
        addAdditionalCommissionInvoice() {
            this.model.invoiceData.additionalCommissionInvoices.push({
                InvoiceNumber: '',
                NetValue: null,
                VatValue: null,
                GrossValue: null,
            })
        },
        removeAdditionalCommissionInvoice(index) {
            this.model.invoiceData.additionalCommissionInvoices.splice(index, 1)
        },
        addAdditionalSalesInvoice() {
            this.model.invoiceData.additionalSalesInvoices.push({
                InvoiceNumber: '',
                NetValue: null,
                VatValue: null,
                GrossValue: null,
            })
        },
        removeAdditionalSalesInvoice(index) {
            this.model.invoiceData.additionalSalesInvoices.splice(index, 1)
        },
    },
}
</script>
