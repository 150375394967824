<template>
    <div v-if="value.label">
        {{ value.label }}
    </div>
    <div v-else-if="typeof value == 'object'">
        <div v-for="(v, k) in value" :key="k">
            {{ k }}: <diff-value :value="v"></diff-value>
        </div>
    </div>
    <div v-else-if="value === true">true</div>
    <div v-else-if="value === false">false</div>
    <div v-else>{{ value }}</div>
</template>

<script>
export default {
    name: 'DiffValue',
    props: {
        value: {
            type: [String, Number, Object, Array, Boolean],
            default: null,
        },
    },
}
</script>
