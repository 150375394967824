<script>
import { mapGetters, mapActions } from 'vuex'
import deliveryType from '@/store/type/deliveryType'
import restaurantType from '@/store/type/restaurantType'
import ApiClientType from '@/api/RestApiType'
import SelectApiClientType from '@/api/SelectApiClientType'
import RestApiCollection from '@/api/RestApiCollection'
import IdFromIri from '@/services/IdFromIri'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'
import OrderGeneralTabEvent from '@/pages/orders/mixins/OrderGeneralTabEvent'
import FormPanel from '@/components/form/FormPanel.vue'
import OrderSoftwareSending from '@/components/pages/orders/OrditSoftwareSending.vue'
import DeliveryStatusBlock from '@/components/pages/orders/tabs/general/DeliveryStatusBlock.vue'
import DeliveryDetailsBlock from '@/components/pages/orders/tabs/general/DeliveryDetailsBlock.vue'
import OrderDetailsBlock from '@/components/pages/orders/tabs/general/OrderDetailsBlock.vue'
import TextInput from '@/components/form/Inputs/TextInput.vue'
import TextareaInput from '@/components/form/Inputs/TextareaInput.vue'

export default {
    components: {
        TextInput,
        TextareaInput,
        OrderDetailsBlock,
        DeliveryDetailsBlock,
        DeliveryStatusBlock,
        FormPanel,
        OrderSoftwareSending,
    },
    mixins: [FormTabMixin, FormInput, OrderGeneralTabEvent],
    props: {
        isValid: {
            type: Boolean,
        },
        mapper: {
            type: String,
            required: true,
        },
        api: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            apiDeliveries: ApiClientType.DELIVERIES,
            selectApiClientType: SelectApiClientType,
        }
    },

    computed: {
        ...mapGetters({
            busy: `${deliveryType.getters.BUSY}`,
        }),

        warnings() {
            let warnings = this.model.warning
                ? this.model.warnings
                : this.model?.deliveryJob?.warnings
            if (typeof warnings === 'string') warnings = JSON.parse(warnings)
            return warnings || []
        },
    },

    watch: {
        'model.deliveryJob': {
            immediate: true,
            handler(val) {
                RestApiCollection.get(this.apiDeliveries).getDeliveryJob(
                    val?.id
                )
                RestApiCollection.get(this.apiDeliveries).getProviderJob(
                    this.model?.identifier
                )
            },
        },
        'model.restaurant': {
            immediate: true,
            handler(val) {
                if (val == null) return
                this.getRestaurant(IdFromIri.getId(val))
            },
        },
    },

    methods: {
        ...mapActions({
            getRestaurant: restaurantType.actions.GET_RESTAURANT,
        }),
        setSoftwareSending(val) {
            const idx = this.model.softwareSendings.findIndex((el) => {
                return el['@id'] == val['@id']
            })
            if (idx !== -1) this.$set(this.model.softwareSendings, idx, val)
        },
    },
}
</script>

<template lang="pug">
v-tab-item(key="general_tab" :transition="false" :reverse-transition="false")
    .pt-5
        v-chip.rounded.box-label.mb-1.mr-2.white--text(v-for='warning in warnings' :key="`orderWarning-${warning}`" small color='red') {{ $t(`delivery_warning.${warning}`) }}
    v-row.pt-2
        v-col(v-if="model && model.deliveryJob" cols="12" sm="4")
            delivery-status-block(ref="statusBlock" :model="model")
        v-col(cols="12" sm="4")
            delivery-details-block(:model="model" :api="api" :mapper="mapper" :status-ref="$refs.statusBlock")
        v-col(cols="12" sm="4")
            order-details-block(:model="model")

    form-panel.mb-4(:title="$t('labels.admin_note')")
        // ADMIN NOTE
        textarea-input(v-model="model.note" v-validate.immediate="'space'" name="admin_note" label="labels.admin_note" :data-vv-as="$t('labels.admin_note')" :error-messages="errors.collect('admin_note')")
        // THIRD PARTY
        h3.headline {{ $t('labels.third_party') }}
        v-row
            v-col
                text-input(v-model="model.thirdPartyOrderId" v-validate.immediate="'space|max:128'" name="thirdPartyOrderId" label="labels.third_party_order_id" :data-vv-as="$t('labels.third_party_order_id')" :error-messages="errors.collect('thirdPartyOrderId')" counter="128")
            v-col
                text-input(v-model="model.thirdPartyTrackingUrl" v-validate.immediate="'space|max:255'" name="thirdPartyTrackingUrl" label="labels.third_party_tracking_url" :data-vv-as="$t('labels.third_party_tracking_url')" :error-messages="errors.collect('thirdPartyTrackingUrl')" counter="255")
            v-col
                text-input(v-model="model.thirdPartyCode" v-validate.immediate="'space|max:128'" name="thirdPartyCode" label="labels.third_party_code" :data-vv-as="$t('labels.third_party_code')" :error-messages="errors.collect('thirdPartyCode')" counter="128")

    form-panel.mb-4(v-if="model.softwareSendings && model.softwareSendings.length" :title="$t('labels.software_sendings')")
        // SOFTWARE SENDINGS
        order-software-sending(v-for="sSending in model.softwareSendings" :key="sSending['@id']" :value="sSending" @input="setSoftwareSending")

</template>
