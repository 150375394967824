<template lang="pug">
.edit-order
    v-btn.px-0(
        color="primary"
        @click="setDialog(true)" text x-small)
        span.text-subtitle-2 {{$t('labels.change')}}
        v-icon(dense) chevron_right

    modal-dialog.edit-order-modal(
        v-model="dialog"
        :title="$t('labels.edit_order_details')"
        :ok-text="$t('actions.confirm')"
        :ok-loading="loading"
        :show-overlay="loading"
        @cancel="setDialog(false)"
        @ok="onApprove"
        cancel-button)
        v-row
            v-col(cols="12")
                entity-select-input(
                    v-model='innerModel.paymentMethod'
                    label="labels.payment"
                    :data-vv-as="$t('labels.payment')"
                    :entity="selectApiClientType.PAYMENT_METHODS"
                    :error-messages="errors.collect('paymentMethod')"
                    name="paymentMethod"
                    autocomplete
                    translate-items)
            v-col(cols="12" md="6")
                text-input(
                    v-model="innerModel.priceSum"
                    v-validate.immediate="'space'"
                    name="priceSum"
                    label="labels.price_sum"
                    :suffix="currencySuffix"
                    :prefix="currencyPrefix"
                    :error-messages="errors.collect('labels.price_sum')"
                    :data-vv-as="$t('labels.price_sum')")
            v-col(cols="12" md="6")
                text-input(
                    v-model="innerModel.priceSumFirm"
                    v-validate.immediate="'space'"
                    name="priceSumFirm"
                    label="labels.price_sum_firm"
                    :suffix="currencySuffix"
                    :prefix="currencyPrefix"
                    :error-messages="errors.collect('labels.price_sum_firm')"
                    :data-vv-as="$t('labels.price_sum_firm')")
            v-col(cols="12" md="6")
                text-input(
                    v-model="innerModel.priceSumUser"
                    v-validate.immediate="'space'"
                    name="priceSumUser"
                    label="labels.price_sum_user"
                    :suffix="currencySuffix"
                    :prefix="currencyPrefix"
                    :error-messages="errors.collect('labels.price_sum_user')"
                    :data-vv-as="$t('labels.price_sum_user')")
            v-col(cols="12" md="6")
                text-input(
                    v-model="innerModel.priceDelivery"
                    v-validate.immediate="'space'"
                    name="priceDelivery"
                    label="labels.price_delivery"
                    :suffix="currencySuffix"
                    :prefix="currencyPrefix"
                    :error-messages="errors.collect('labels.price_delivery')"
                    :data-vv-as="$t('labels.price_delivery')")
            v-col(cols="12")
                textarea-input(
                    v-model='innerModel.comment'
                    v-validate.immediate="'space'"
                    name='comment'
                    label='labels.comment'
                    :data-vv-as="$t('labels.comment')"
                    :error-messages="errors.collect('comment')")
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import RestApiType from '@/api/RestApiType'
import SelectApiClientType from '@/api/SelectApiClientType'
import MapperType from '@/services/mapper/MapperType'
import baseFormType from '@/store/type/baseFormType'
import currencySymbol from '@/mixins/currencySymbol'
import ModalDialog from '@/components/dialog/ModalDialog.vue'
import TextInput from '@/components/form/Inputs/TextInput.vue'
import TextareaInput from '@/components/form/Inputs/TextareaInput.vue'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput.vue'

export default {
    components: {
        EntitySelectInput,
        ModalDialog,
        TextInput,
        TextareaInput,
    },
    mixins: [currencySymbol],
    props: {
        model: Object,
    },
    data() {
        return {
            selectApiClientType: SelectApiClientType,
            dialog: false,
            loading: false,
            innerModel: {},
        }
    },
    computed: {
        symbol() {
            return this.getCurrencySymbol(this.model.currency).symbol
        },
        suffix() {
            return this.getCurrencySymbol(this.model.currency).suffix
        },
        currencySuffix() {
            return this.suffix ? this.symbol : undefined
        },
        currencyPrefix() {
            return !this.suffix ? this.symbol : undefined
        },
    },
    watch: {
        value() {
            this.dialog = this.value
        },
    },
    methods: {
        setDialog(value) {
            this.dialog = value
            if (this.dialog) {
                this.innerModel = cloneDeep(this.model)
            }
        },
        onApprove() {
            this.loading = true
            this.$store
                .dispatch(`baseForm/${baseFormType.actions.SAVE}`, {
                    model: this.innerModel,
                    api: RestApiType.ORDERS,
                    id: this.innerModel.id,
                    mapper: MapperType.ORDER_FORM,
                })
                .then(() => {
                    this.setDialog(false)
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
}
</script>
