<template>
    <v-tab-item
        key="history_tab"
        :transition="false"
        :reverse-transition="false"
    >
        <data-iterator
            v-if="id"
            ref="iterator"
            :api="api"
            :filters.sync="filters"
            :fixed-filters="fixedFilters"
        >
            <template slot-scope="{ items }">
                <d-table
                    :items="items"
                    :headers="headers"
                    :filters.sync="filters"
                    class="order-history-table"
                >
                    <template v-slot:[`item.createdAt`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <span v-on="on">
                                    {{ item.createdAt | transformDate }}
                                </span>
                            </template>
                            <span>{{ item.createdAt }}</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:[`item.payload`]="{ item }">
                        <div
                            v-for="(values, key) in item.payload"
                            :key="key"
                            class="d-flex justify-space-between line-separator"
                        >
                            <code class="text-break">{{ key }}</code>
                            <code class="text-break">
                                <diff-value :value="values"></diff-value>
                            </code>
                        </div>
                        <div v-if="item.payload.length === 0">--</div>
                    </template>
                </d-table>
            </template>
        </data-iterator>
    </v-tab-item>
</template>

<script>
import RestApiType from '@/api/RestApiType'
import FormInputMixin from '@/components/mixins/FormInput'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import DiffValue from '../../audit/DiffValue.vue'

export default {
    components: { DiffValue },
    mixins: [FormInputMixin, DataIteratorMixin, DataTableMixin],
    data: () => ({
        api: RestApiType.ORDER_HISTORY,
    }),
    computed: {
        id() {
            return this.value?.id
        },
        fixedFilters() {
            return {
                order: {
                    createdAt: 'desc',
                },
                'ordering.id': this.id,
            }
        },
        headers() {
            return [
                {
                    value: 'createdAt',
                    text: this.$t('labels.created_at'),
                    sortable: false,
                },
                {
                    value: 'type',
                    text: this.$t('labels.type'),
                    sortable: false,
                },
                {
                    value: 'payload',
                    text: this.$t('labels.payload'),
                    sortable: false,
                },
            ]
        },
    },
    methods: {},
}
</script>

<style>
.line-separator {
    padding-top: 2px;
    padding-bottom: 2px;
}
.line-separator:not(:last-of-type) {
    border-bottom: 1px solid var(--v-g-aa-base);
}
</style>
