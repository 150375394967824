<template lang="pug">
.request-driver
    v-btn.px-0(
        color="primary"
        :disabled="!model.canYouCreateProviderJob"
        @click="setDialog(true)" text x-small)
        span.text-subtitle-2 {{$t('labels.new_driver')}}
        v-icon(dense) chevron_right
    modal-dialog.request-driver-modal(
        v-model="dialog"
        :title="$t('labels.new_driver')"
        :show-overlay="loading"
        :ok-text="$t('actions.request')"
        :ok-loading="loading"
        @cancel="onCancel"
        @ok="createRequestNewDelivery"
        :ok-disabled="!deliveryMethod"
        cancel-button)
        template
            date-picker-input(v-model="pickupTime" label="orders.pickup_date_now" :min="$date().format()")
            label.text-h6.text-dense {{$t('labels.delivery_method')}}
            v-radio-group.mt-0(v-model="deliveryMethod" dense)
                v-radio(
                    v-for="(method,index) in deliveryMethods"
                    :key="`DeliveryRule-${index}`"
                    :value="method"
                    :label="method.label")
            v-checkbox(v-model="noAutoCancel" :false-value="false" :label="$t('labels.turn_off_auto_cancel')")
            v-checkbox(v-model="orderStacking" :false-value="false" :label="$t('labels.small_package_stack')")


</template>

<script>
import ModalDialog from '@/components/dialog/ModalDialog'
import DeliveriesApiClient from '@/api/RestApi/DeliveriesApiClient'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'
import SelectApiClientType from '@/api/SelectApiClientType'
import selectapiType from '@/store/type/selectapiType'
import PublicApiClient from '@/api/RestApi/PublicApiClient'
import { DATE_TIME_FORMAT } from '@/const/DateTimeFormats'

const ALL = 'ALL'
const RESTAURANT_MANAGED = 'RESTAURANT_MANAGED'

export default {
    components: {
        DatePickerInput,
        ModalDialog,
    },
    props: {
        model: {
            type: Object,
            default: () => {},
        },
        identifier: null,
    },
    data() {
        return {
            dialog: false,
            loading: false,
            noAutoCancel: false,
            deliveryMethod: null,
            pickupTime: null,
            deliveryMethods: [],
            selectApiClientType: SelectApiClientType,
            orderStacking: false,
        }
    },

    methods: {
        async getMethods() {
            this.deliveryMethods = await this.$store.dispatch(
                selectapiType.getActionName(
                    this.selectApiClientType.DELIVERY_METHOD,
                    selectapiType.actions.LOAD
                )
            )
            this.deliveryMethods = this.deliveryMethods.filter(
                (method) => method.id !== RESTAURANT_MANAGED
            )
            // handle stuart large deliveries
            const stuartId = this.deliveryMethods.findIndex(
                (method) => method.key === 'stuart'
            )
            if (stuartId !== -1) {
                this.deliveryMethods.splice(stuartId + 1, 0, {
                    id: 'STUART_LARGE',
                    key: 'stuart_large',
                    label: 'enums.delivery_methods.stuart_large',
                    name: 'stuart_large',
                })
                this.deliveryMethods.splice(stuartId + 2, 0, {
                    id: 'STUART_XLARGE',
                    key: 'stuart_xlarge',
                    label: 'enums.delivery_methods.stuart_xlarge',
                    name: 'stuart_xlarge',
                })
            }
            this.deliveryMethods.forEach((method) => {
                method.label = this.$t(method.label)
            })
            this.deliveryMethods.unshift({
                id: 'ALL',
                key: this.deliveryMethods.reduce((a, b) => {
                    a.push(b.key)
                    return a
                }, []),
                label: this.deliveryMethods.reduce(
                    (a, b, index) =>
                        `${a}${index ? ', ' : ''}${b.label}${
                            index + 1 === this.deliveryMethods.length ? ']' : ''
                        }`,
                    `${this.$t(`enums.delivery_methods.all`)} [`
                ),
            })
            return true
        },
        createProviderJob(method, debounce = 0) {
            return new Promise((resolve) => {
                setTimeout(async () => {
                    const payload = {
                        method: method,
                        disableAutoCancel: this.noAutoCancel,
                        settings: {
                            small: this.orderStacking,
                        },
                    }
                    // handle stuart large deliveries
                    if (method === 'stuart_large') {
                        payload.method = 'stuart'
                        payload.settings = {
                            large: true,
                        }
                    }
                    if (method === 'stuart_xlarge') {
                        payload.method = 'stuart'
                        payload.settings = {
                            xlarge: true,
                        }
                    }
                    await DeliveriesApiClient.createAndQueueDeliveryProviderJob(
                        payload,
                        this.model.id
                    )
                    resolve()
                }, 500 * debounce)
            })
        },
        async setDialog(value) {
            this.dialog = value
            if (this.dialog) {
                if (!this.deliveryMethods.length) await this.getMethods()
                this.deliveryMethod = this.deliveryMethods?.[0]?.value
                this.noAutoCancel = false
                this.orderStacking = false
            }
        },
        onApprove() {
            this.setDialog(false)
            this.$emit('onApprove')
        },
        onCancel() {
            this.setDialog(false)
            this.$emit('onCancel')
        },
        async createRequestNewDelivery() {
            this.loading = true
            const pickupTime = this.pickupTime
                ? this.$date(this.pickupTime).format(DATE_TIME_FORMAT)
                : this.$date((await PublicApiClient.getDateTime()).datetime)
                      .add(1, 'm')
                      .format(DATE_TIME_FORMAT)

            DeliveriesApiClient.updateDeliveryJob({
                ...this.model,
                ...{
                    pickupTime,
                },
            })
                .then(() => {
                    Promise.all(
                        this.deliveryMethod.id === ALL
                            ? this.deliveryMethod.key.map((method, index) =>
                                  this.createProviderJob(method, index)
                              )
                            : [this.createProviderJob(this.deliveryMethod.key)]
                    )
                        .then(async () => {
                            const result = await DeliveriesApiClient.getProviderJob(
                                this.identifier
                            )
                            if (result['@id']) this.onApprove()
                            this.loading = false
                        })
                        .catch(() => {
                            this.loading = false
                        })
                })
                .catch(() => {
                    this.loading = false
                })
        },
    },
}
</script>
