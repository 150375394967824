var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tab-item',{key:"invoice_tab",attrs:{"transition":false,"reverse-transition":false}},[(_vm.model.invoiceData)?_c('form-panel',{attrs:{"title":_vm.$t('orders.invoice_settings')}},[_c('div',[_c('checkbox-input',{staticClass:"d-inline-block",attrs:{"label":"orders.invoice.supplier"},model:{value:(_vm.supplier),callback:function ($$v) {_vm.supplier=$$v},expression:"supplier"}})],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.supplier),expression:"supplier"}]},[_c('v-col',{attrs:{"cols":"4"}},[_c('text-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(_vm.supplier, { space: true })
                    ),expression:"\n                        rowValidator(supplier, { space: true })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":"supplierInvoiceInvoiceNumber","label":"orders.invoice.number","error-messages":_vm.errors.collect('supplierInvoiceInvoiceNumber'),"data-vv-as":_vm.$t('orders.invoice.number')},model:{value:(_vm.model.invoiceData.supplierInvoiceInvoiceNumber),callback:function ($$v) {_vm.$set(_vm.model.invoiceData, "supplierInvoiceInvoiceNumber", $$v)},expression:"model.invoiceData.supplierInvoiceInvoiceNumber"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(_vm.supplier, {
                            decimal: {
                                decimals: 2,
                            },
                        })
                    ),expression:"\n                        rowValidator(supplier, {\n                            decimal: {\n                                decimals: 2,\n                            },\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":"supplierInvoiceNetValue","label":"orders.invoice.net","error-messages":_vm.errors.collect('supplierInvoiceNetValue'),"data-vv-as":_vm.$t('orders.invoice.net')},model:{value:(_vm.model.invoiceData.supplierInvoiceNetValue),callback:function ($$v) {_vm.$set(_vm.model.invoiceData, "supplierInvoiceNetValue", $$v)},expression:"model.invoiceData.supplierInvoiceNetValue"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(_vm.supplier, {
                            decimal: {
                                decimals: 2,
                            },
                        })
                    ),expression:"\n                        rowValidator(supplier, {\n                            decimal: {\n                                decimals: 2,\n                            },\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":"supplierInvoiceVatValue","label":"orders.invoice.vat","error-messages":_vm.errors.collect('supplierInvoiceVatValue'),"data-vv-as":_vm.$t('orders.invoice.vat')},model:{value:(_vm.model.invoiceData.supplierInvoiceVatValue),callback:function ($$v) {_vm.$set(_vm.model.invoiceData, "supplierInvoiceVatValue", $$v)},expression:"model.invoiceData.supplierInvoiceVatValue"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(_vm.supplier, {
                            decimal: {
                                decimals: 2,
                            },
                            'invoice-sum': [
                                _vm.model.invoiceData.supplierInvoiceNetValue,
                                _vm.model.invoiceData.supplierInvoiceVatValue ],
                        })
                    ),expression:"\n                        rowValidator(supplier, {\n                            decimal: {\n                                decimals: 2,\n                            },\n                            'invoice-sum': [\n                                model.invoiceData.supplierInvoiceNetValue,\n                                model.invoiceData.supplierInvoiceVatValue,\n                            ],\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":"supplierInvoiceGrossValue","label":"orders.invoice.gross","error-messages":_vm.errors.collect('supplierInvoiceGrossValue'),"data-vv-as":_vm.$t('orders.invoice.gross')},model:{value:(_vm.model.invoiceData.supplierInvoiceGrossValue),callback:function ($$v) {_vm.$set(_vm.model.invoiceData, "supplierInvoiceGrossValue", $$v)},expression:"model.invoiceData.supplierInvoiceGrossValue"}})],1)],1),_vm._l((_vm.model.invoiceData
                .additionalSupplierInvoices),function(invoice,index){return _c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.supplier),expression:"supplier"}],key:index},[_c('v-col',{attrs:{"cols":"4"}},[_c('text-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(invoice, { space: true })
                    ),expression:"\n                        rowValidator(invoice, { space: true })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":("model.invoiceData.additionalSupplierInvoices[" + index + "].InvoiceNumber"),"label":"orders.invoice.number","error-messages":_vm.errors.collect(
                            ("model.invoiceData.additionalSupplierInvoices[" + index + "].InvoiceNumber")
                        ),"data-vv-as":_vm.$t('orders.invoice.number')},model:{value:(invoice.InvoiceNumber),callback:function ($$v) {_vm.$set(invoice, "InvoiceNumber", $$v)},expression:"invoice.InvoiceNumber"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(invoice, {
                            decimal: { decimals: 2 },
                        })
                    ),expression:"\n                        rowValidator(invoice, {\n                            decimal: { decimals: 2 },\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":("model.invoiceData.additionalSupplierInvoices[" + index + "].NetValue"),"label":"orders.invoice.net","error-messages":_vm.errors.collect(
                            ("model.invoiceData.additionalSupplierInvoices[" + index + "].NetValue")
                        ),"data-vv-as":_vm.$t('orders.invoice.net')},model:{value:(invoice.NetValue),callback:function ($$v) {_vm.$set(invoice, "NetValue", $$v)},expression:"invoice.NetValue"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(invoice, {
                            decimal: { decimals: 2 },
                        })
                    ),expression:"\n                        rowValidator(invoice, {\n                            decimal: { decimals: 2 },\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":("model.invoiceData.additionalSupplierInvoices[" + index + "].VatValue"),"label":"orders.invoice.vat","error-messages":_vm.errors.collect(
                            ("model.invoiceData.additionalSupplierInvoices[" + index + "].VatValue")
                        ),"data-vv-as":_vm.$t('orders.invoice.vat')},model:{value:(invoice.VatValue),callback:function ($$v) {_vm.$set(invoice, "VatValue", $$v)},expression:"invoice.VatValue"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(invoice, {
                            decimal: { decimals: 2 },
                            'invoice-sum': [
                                invoice.NetValue,
                                invoice.VatValue ],
                        })
                    ),expression:"\n                        rowValidator(invoice, {\n                            decimal: { decimals: 2 },\n                            'invoice-sum': [\n                                invoice.NetValue,\n                                invoice.VatValue,\n                            ],\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":("model.invoiceData.additionalSupplierInvoices[" + index + "].GrossValue"),"label":"orders.invoice.gross","error-messages":_vm.errors.collect(
                            ("model.invoiceData.additionalSupplierInvoices[" + index + "].GrossValue")
                        ),"data-vv-as":_vm.$t('orders.invoice.gross')},model:{value:(invoice.GrossValue),callback:function ($$v) {_vm.$set(invoice, "GrossValue", $$v)},expression:"invoice.GrossValue"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"mt-5",attrs:{"color":"error","icon":"","small":""},on:{"click":function($event){return _vm.removeAdditionalSupplierInvoicesInvoiceInvoice(
                            index
                        )}}},[_c('v-icon',[_vm._v("close")])],1)],1)],1)}),(_vm.supplier)?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.addAdditionalSupplierInvoicesInvoice}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(_vm._s(_vm.$t('orders.invoice.add_more'))+" ")],1)],1)],1):_vm._e(),_c('div',[_c('checkbox-input',{staticClass:"d-inline-block",attrs:{"label":"orders.invoice.commission"},model:{value:(_vm.commission),callback:function ($$v) {_vm.commission=$$v},expression:"commission"}})],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.commission),expression:"commission"}]},[_c('v-col',{attrs:{"cols":"4"}},[_c('text-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(_vm.commission, { space: true })
                    ),expression:"\n                        rowValidator(commission, { space: true })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":"commissionInvoiceInvoiceNumber","label":"orders.invoice.number","error-messages":_vm.errors.collect('commissionInvoiceInvoiceNumber'),"data-vv-as":_vm.$t('orders.invoice.number')},model:{value:(
                        _vm.model.invoiceData.commissionInvoiceInvoiceNumber
                    ),callback:function ($$v) {_vm.$set(_vm.model.invoiceData, "commissionInvoiceInvoiceNumber", $$v)},expression:"\n                        model.invoiceData.commissionInvoiceInvoiceNumber\n                    "}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(_vm.commission, {
                            decimal: {
                                decimals: 2,
                            },
                        })
                    ),expression:"\n                        rowValidator(commission, {\n                            decimal: {\n                                decimals: 2,\n                            },\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":"commissionInvoiceNetValue","label":"orders.invoice.net","error-messages":_vm.errors.collect('commissionInvoiceNetValue'),"data-vv-as":_vm.$t('orders.invoice.net')},model:{value:(_vm.model.invoiceData.commissionInvoiceNetValue),callback:function ($$v) {_vm.$set(_vm.model.invoiceData, "commissionInvoiceNetValue", $$v)},expression:"model.invoiceData.commissionInvoiceNetValue"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(_vm.commission, {
                            decimal: {
                                decimals: 2,
                            },
                        })
                    ),expression:"\n                        rowValidator(commission, {\n                            decimal: {\n                                decimals: 2,\n                            },\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":"commissionInvoiceVatValue","label":"orders.invoice.vat","error-messages":_vm.errors.collect('commissionInvoiceVatValue'),"data-vv-as":_vm.$t('orders.invoice.vat')},model:{value:(_vm.model.invoiceData.commissionInvoiceVatValue),callback:function ($$v) {_vm.$set(_vm.model.invoiceData, "commissionInvoiceVatValue", $$v)},expression:"model.invoiceData.commissionInvoiceVatValue"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(_vm.commission, {
                            decimal: {
                                decimals: 2,
                            },
                            'invoice-sum': [
                                _vm.model.invoiceData.commissionInvoiceNetValue,
                                _vm.model.invoiceData.commissionInvoiceVatValue ],
                        })
                    ),expression:"\n                        rowValidator(commission, {\n                            decimal: {\n                                decimals: 2,\n                            },\n                            'invoice-sum': [\n                                model.invoiceData.commissionInvoiceNetValue,\n                                model.invoiceData.commissionInvoiceVatValue,\n                            ],\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":"commissionInvoiceGrossValue","label":"orders.invoice.gross","error-messages":_vm.errors.collect('commissionInvoiceGrossValue'),"data-vv-as":_vm.$t('orders.invoice.gross')},model:{value:(_vm.model.invoiceData.commissionInvoiceGrossValue),callback:function ($$v) {_vm.$set(_vm.model.invoiceData, "commissionInvoiceGrossValue", $$v)},expression:"model.invoiceData.commissionInvoiceGrossValue"}})],1)],1),_vm._l((_vm.model.invoiceData
                .additionalCommissionInvoices),function(invoice,index){return _c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.commission),expression:"commission"}],key:index},[_c('v-col',{attrs:{"cols":"4"}},[_c('text-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(invoice, { space: true })
                    ),expression:"\n                        rowValidator(invoice, { space: true })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":("model.invoiceData.additionalCommissionInvoices[" + index + "].InvoiceNumber"),"label":"orders.invoice.number","error-messages":_vm.errors.collect(
                            ("model.invoiceData.additionalCommissionInvoices[" + index + "].InvoiceNumber")
                        ),"data-vv-as":_vm.$t('orders.invoice.number')},model:{value:(invoice.InvoiceNumber),callback:function ($$v) {_vm.$set(invoice, "InvoiceNumber", $$v)},expression:"invoice.InvoiceNumber"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(invoice, {
                            decimal: {
                                decimals: 2,
                            },
                        })
                    ),expression:"\n                        rowValidator(invoice, {\n                            decimal: {\n                                decimals: 2,\n                            },\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":("model.invoiceData.additionalCommissionInvoices[" + index + "].NetValue"),"label":"orders.invoice.net","error-messages":_vm.errors.collect(
                            ("model.invoiceData.additionalCommissionInvoices[" + index + "].NetValue")
                        ),"data-vv-as":_vm.$t('orders.invoice.net')},model:{value:(invoice.NetValue),callback:function ($$v) {_vm.$set(invoice, "NetValue", $$v)},expression:"invoice.NetValue"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(invoice, {
                            decimal: {
                                decimals: 2,
                            },
                        })
                    ),expression:"\n                        rowValidator(invoice, {\n                            decimal: {\n                                decimals: 2,\n                            },\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":("model.invoiceData.additionalCommissionInvoices[" + index + "].VatValue"),"label":"orders.invoice.vat","error-messages":_vm.errors.collect(
                            ("model.invoiceData.additionalCommissionInvoices[" + index + "].VatValue")
                        ),"data-vv-as":_vm.$t('orders.invoice.vat')},model:{value:(invoice.VatValue),callback:function ($$v) {_vm.$set(invoice, "VatValue", $$v)},expression:"invoice.VatValue"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(invoice, {
                            decimal: {
                                decimals: 2,
                            },
                            'invoice-sum': [
                                invoice.NetValue,
                                invoice.VatValue ],
                        })
                    ),expression:"\n                        rowValidator(invoice, {\n                            decimal: {\n                                decimals: 2,\n                            },\n                            'invoice-sum': [\n                                invoice.NetValue,\n                                invoice.VatValue,\n                            ],\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":("model.invoiceData.additionalCommissionInvoices[" + index + "].GrossValue"),"label":"orders.invoice.gross","error-messages":_vm.errors.collect(
                            ("model.invoiceData.additionalCommissionInvoices[" + index + "].GrossValue")
                        ),"data-vv-as":_vm.$t('orders.invoice.gross')},model:{value:(invoice.GrossValue),callback:function ($$v) {_vm.$set(invoice, "GrossValue", $$v)},expression:"invoice.GrossValue"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"mt-5",attrs:{"color":"error","icon":"","small":""},on:{"click":function($event){return _vm.removeAdditionalCommissionInvoice(index)}}},[_c('v-icon',[_vm._v("close")])],1)],1)],1)}),(_vm.commission)?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.addAdditionalCommissionInvoice}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t('orders.invoice.add_more'))+" ")],1)],1)],1):_vm._e(),_c('div',[_c('checkbox-input',{staticClass:"d-inline-block",attrs:{"label":"orders.invoice.sales"},model:{value:(_vm.sales),callback:function ($$v) {_vm.sales=$$v},expression:"sales"}})],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.sales),expression:"sales"}]},[_c('v-col',{attrs:{"cols":"4"}},[_c('text-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(_vm.sales, { space: true })
                    ),expression:"\n                        rowValidator(sales, { space: true })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":"salesInvoiceInvoiceNumber","label":"orders.invoice.number","error-messages":_vm.errors.collect('salesInvoiceInvoiceNumber'),"data-vv-as":_vm.$t('orders.invoice.number')},model:{value:(_vm.model.invoiceData.salesInvoiceInvoiceNumber),callback:function ($$v) {_vm.$set(_vm.model.invoiceData, "salesInvoiceInvoiceNumber", $$v)},expression:"model.invoiceData.salesInvoiceInvoiceNumber"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(_vm.sales, {
                            decimal: {
                                decimals: 2,
                            },
                        })
                    ),expression:"\n                        rowValidator(sales, {\n                            decimal: {\n                                decimals: 2,\n                            },\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":"salesInvoiceNetValue","label":"orders.invoice.net","error-messages":_vm.errors.collect('salesInvoiceNetValue'),"data-vv-as":_vm.$t('orders.invoice.net')},model:{value:(_vm.model.invoiceData.salesInvoiceNetValue),callback:function ($$v) {_vm.$set(_vm.model.invoiceData, "salesInvoiceNetValue", $$v)},expression:"model.invoiceData.salesInvoiceNetValue"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(_vm.sales, {
                            decimal: {
                                decimals: 2,
                            },
                        })
                    ),expression:"\n                        rowValidator(sales, {\n                            decimal: {\n                                decimals: 2,\n                            },\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":"salesInvoiceVatValue","label":"orders.invoice.vat","error-messages":_vm.errors.collect('salesInvoiceVatValue'),"data-vv-as":_vm.$t('orders.invoice.vat')},model:{value:(_vm.model.invoiceData.salesInvoiceVatValue),callback:function ($$v) {_vm.$set(_vm.model.invoiceData, "salesInvoiceVatValue", $$v)},expression:"model.invoiceData.salesInvoiceVatValue"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(_vm.sales, {
                            decimal: {
                                decimals: 2,
                            },
                            'invoice-sum': [
                                _vm.model.invoiceData.salesInvoiceNetValue,
                                _vm.model.invoiceData.salesInvoiceVatValue ],
                        })
                    ),expression:"\n                        rowValidator(sales, {\n                            decimal: {\n                                decimals: 2,\n                            },\n                            'invoice-sum': [\n                                model.invoiceData.salesInvoiceNetValue,\n                                model.invoiceData.salesInvoiceVatValue,\n                            ],\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":"salesInvoiceGrossValue","label":"orders.invoice.gross","error-messages":_vm.errors.collect('salesInvoiceGrossValue'),"data-vv-as":_vm.$t('orders.invoice.gross')},model:{value:(_vm.model.invoiceData.salesInvoiceGrossValue),callback:function ($$v) {_vm.$set(_vm.model.invoiceData, "salesInvoiceGrossValue", $$v)},expression:"model.invoiceData.salesInvoiceGrossValue"}})],1)],1),_vm._l((_vm.model.invoiceData
                .additionalSalesInvoices),function(invoice,index){return _c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.sales),expression:"sales"}],key:index},[_c('v-col',{attrs:{"cols":"4"}},[_c('text-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(invoice, { space: true })
                    ),expression:"\n                        rowValidator(invoice, { space: true })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":("model.invoiceData.additionalSalesInvoices[" + index + "].InvoiceNumber"),"label":"orders.invoice.number","error-messages":_vm.errors.collect(
                            ("model.invoiceData.additionalSalesInvoices[" + index + "].InvoiceNumber")
                        ),"data-vv-as":_vm.$t('orders.invoice.number')},model:{value:(invoice.InvoiceNumber),callback:function ($$v) {_vm.$set(invoice, "InvoiceNumber", $$v)},expression:"invoice.InvoiceNumber"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(invoice, {
                            decimal: {
                                decimals: 2,
                            },
                        })
                    ),expression:"\n                        rowValidator(invoice, {\n                            decimal: {\n                                decimals: 2,\n                            },\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":("model.invoiceData.additionalSalesInvoices[" + index + "].NetValue"),"label":"orders.invoice.net","error-messages":_vm.errors.collect(
                            ("model.invoiceData.additionalSalesInvoices[" + index + "].NetValue")
                        ),"data-vv-as":_vm.$t('orders.invoice.net')},model:{value:(invoice.NetValue),callback:function ($$v) {_vm.$set(invoice, "NetValue", $$v)},expression:"invoice.NetValue"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(invoice, {
                            decimal: {
                                decimals: 2,
                            },
                        })
                    ),expression:"\n                        rowValidator(invoice, {\n                            decimal: {\n                                decimals: 2,\n                            },\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":("model.invoiceData.additionalSalesInvoices[" + index + "].VatValue"),"label":"orders.invoice.vat","error-messages":_vm.errors.collect(
                            ("model.invoiceData.additionalSalesInvoices[" + index + "].VatValue")
                        ),"data-vv-as":_vm.$t('orders.invoice.vat')},model:{value:(invoice.VatValue),callback:function ($$v) {_vm.$set(invoice, "VatValue", $$v)},expression:"invoice.VatValue"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('currency-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.rowValidator(invoice, {
                            decimal: {
                                decimals: 2,
                            },
                            'invoice-sum': [
                                invoice.NetValue,
                                invoice.VatValue ],
                        })
                    ),expression:"\n                        rowValidator(invoice, {\n                            decimal: {\n                                decimals: 2,\n                            },\n                            'invoice-sum': [\n                                invoice.NetValue,\n                                invoice.VatValue,\n                            ],\n                        })\n                    ",modifiers:{"immediate":true}}],attrs:{"name":("model.invoiceData.additionalSalesInvoices[" + index + "].GrossValue"),"label":"orders.invoice.gross","error-messages":_vm.errors.collect(
                            ("model.invoiceData.additionalSalesInvoices[" + index + "].GrossValue")
                        ),"data-vv-as":_vm.$t('orders.invoice.gross')},model:{value:(invoice.GrossValue),callback:function ($$v) {_vm.$set(invoice, "GrossValue", $$v)},expression:"invoice.GrossValue"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"mt-5",attrs:{"color":"error","icon":"","small":""},on:{"click":function($event){return _vm.removeAdditionalSalesInvoice(index)}}},[_c('v-icon',[_vm._v("close")])],1)],1)],1)}),(_vm.sales)?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.addAdditionalSalesInvoice}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v("Add Invoice ")],1)],1)],1):_vm._e(),_c('div',[_c('checkbox-input',{staticClass:"d-inline-block",attrs:{"label":"orders.invoice.paid"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.date),expression:"date"}]},[_c('v-col',{staticClass:"date-input-space",attrs:{"cols":"4"}},[_c('date-picker-input',{directives:[{name:"tab-error",rawName:"v-tab-error:invoices",arg:"invoices"},{name:"validate",rawName:"v-validate.immediate",value:('date_format:yyyy-MM-dd'),expression:"'date_format:yyyy-MM-dd'",modifiers:{"immediate":true}}],attrs:{"name":"supplierPaidDate","label":"orders.invoice.paid_date","only-date":"","error-messages":_vm.errors.collect('supplierPaidDate'),"data-vv-as":_vm.$t('orders.invoice.paid_date')},model:{value:(_vm.model.invoiceData.supplierPaidDate),callback:function ($$v) {_vm.$set(_vm.model.invoiceData, "supplierPaidDate", $$v)},expression:"model.invoiceData.supplierPaidDate"}})],1)],1),_c('div',[_c('checkbox-input',{staticClass:"d-inline-block",attrs:{"label":"orders.invoice.requires_separate_invoice"},model:{value:(_vm.model.invoiceData.requiresSeparateInvoice),callback:function ($$v) {_vm.$set(_vm.model.invoiceData, "requiresSeparateInvoice", $$v)},expression:"model.invoiceData.requiresSeparateInvoice"}})],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }