<template>
    <base-form
        ref="form"
        v-model="form"
        :api="api"
        :mapper="mapper"
        :entity-id="entityId"
        :title="title"
        :disabled="disabled"
        :disable-delete-button="true"
        @valid="isValid = $event"
        @updated="updated"
        @cancelled="cancelled"
    >
        <v-row class="pt-2">
            <v-col>
                <delivery-status-block :model="form"></delivery-status-block>
            </v-col>
            <v-col cols="12" sm="4">
                <order-details-block :model="form" :for-company="true">
                    <v-divider class="my-2"></v-divider>
                    <div class="text-body-1 g-26--text font-weight-medium mb-1">
                        {{ $t('labels.comment') }}
                    </div>
                    <textarea-input
                        v-model="form.comment"
                        v-validate.immediate="'space'"
                        name="comment"
                        :data-vv-as="$t('labels.comment')"
                        :error-messages="errors.collect('comment')"
                    ></textarea-input>
                    <div class="text-body-1 g-26--text mt-4">
                        <div class="d-flex align-center flex-wrap mb-1">
                            <div class="d-flex">
                                <v-icon class="mr-1" color="g-26" dense>
                                    receipt
                                </v-icon>
                                {{ $t('labels.expense_code') }}:
                            </div>
                            <v-btn
                                class="px-0 ml-auto"
                                color="primary"
                                x-small
                                text
                                @click="onCostCodeClick"
                            >
                                <span class="text-subtitle-2">
                                    {{ $t('labels.change') }}
                                </span>
                                <v-icon dense>chevron_right</v-icon>
                            </v-btn>
                        </div>
                        <multi-cost-code-display
                            :item="form"
                            :show-arrow="false"
                            :truncate-codes="false"
                            @click="onCostCodeClick"
                        ></multi-cost-code-display>
                        <multi-cost-code-edit-modal
                            :item="editCostCode"
                            @close="editCostCode = null"
                            @update:item="onCostCodeChange"
                        ></multi-cost-code-edit-modal>
                    </div>
                    <div class="d-flex justify-end mt-3">
                        <print-meal-list :order="form"></print-meal-list>
                    </div>
                </order-details-block>
            </v-col>
        </v-row>
    </base-form>
</template>

<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import deliveryType from '@/store/type/deliveryType'

import FormBase from '@/components/mixins/FormBase'

import TextareaInput from '@/components/form/Inputs/TextareaInput.vue'
import OrderDetailsBlock from './tabs/general/OrderDetailsBlock.vue'
import PrintMealList from './PrintMealList.vue'
import MultiCostCodeDisplay from './parts/MultiCostCodeDisplay.vue'
import MultiCostCodeEditModal from './tableParts/MultiCostCodeEditModal.vue'
import DeliveryStatusBlock from '@/components/pages/orders/tabs/general/DeliveryStatusBlock.vue'

export default {
    components: {
        DeliveryStatusBlock,
        OrderDetailsBlock,
        TextareaInput,
        PrintMealList,
        MultiCostCodeDisplay,
        MultiCostCodeEditModal,
    },
    mixins: [FormBase],
    data() {
        return {
            api: RestApiType.ORDERS,
            mapper: MapperType.ORDER_FORM,
            form: {},
            entity: 'labels.order',
            listRoute: routeType.ORDER_LIST,
            isValid: false,
            editCostCode: null,
        }
    },
    computed: {
        title() {
            return this.form?.identifier ? this.form.identifier : null
        },
    },
    watch: {
        'form.deliveryJob': {
            immediate: true,
            handler(val) {
                this.$store.commit(deliveryType.mutations.SET_DELIVERY_JOB, val)
            },
        },
    },
    methods: {
        onCostCodeClick() {
            this.editCostCode = this.form
        },
        onCostCodeChange(newValue) {
            this.form = newValue
        },
    },
}
</script>
