<template lang="pug">
v-card.border-g-e8(v-if="model && deliveryJob" flat)
    v-card-text.py-2
        v-row(justify="space-between")
            v-col.py-0(cols="auto")
                .text-h6.font-weight-bold
                    template(v-if="deliveryJob.status === 'initialized'") {{$t(`delivery_job_status.initialized`)}}
                    template(v-else-if="deliveryJob.status === 'queued'") {{$t(`delivery_job_status_detailed.queued`, {date: pickupTime})}}
                    template(v-else-if="showEtaToPickup") {{$t(`${etaToPickup > 0 ? 'courier_status_detailed' : 'courier_status'}.${deliveryJob.courierStatus}`, {pickup: etaToPickup})}}
                    template(v-else-if="showEtaToDropoff") {{$t(`${etaToDropoff > 0 ? 'courier_status_detailed' : 'courier_status'}.${deliveryJob.courierStatus}`, {dropoff: etaToDropoff})}}
                    template(v-else-if="deliveryJob.courierStatus") {{$t(`courier_status_detailed.${deliveryJob.courierStatus}`)}}
            v-col.py-0(v-if="(deliveryJob.status === 'cancelled' || deliveryJob.status === 'error') && !forceFinishSuccessful" cols="auto")
                edit-button(
                    :tooltip="$t('labels.force_finish_delivery')"
                    :loading="forceFinishLoading"
                    icon='double_arrow'
                    @execute="forceFinishDeliveryJob()")
        .py-3(v-if="deliveryJob")
            template(v-if="deliveryJob.driverName") {{ deliveryJob.driverName }} |&nbsp;
            template(v-if="deliveryJob.activeDeliveryMethod") {{ deliveryJob.activeDeliveryMethod }} |&nbsp;
            | {{ deliveryJob.driverPhone }}
        address-multiple-map-input(
            style="height: 200px"
            name="deliveryFullAddressMap"
            :addresses="markers"
            :draggable="false")
        .pb-2(v-if="!isSuperAdmin")
        template(v-else)
            .d-flex.align-center.flex-wrap.mb-1.mb-1
                .text-h6.font-weight-bold {{$t('labels.drivers')}}
                request-driver-modal.ml-auto(:model="deliveryJob" :identifier="model.identifier")
                checkbox-input.mt-0(v-if="isStuartDelayRelevant" :value="isStuartDelayEnabled" :disabled="!isStuartDelayEnabled || stuartDelayLoading" label="labels.stuart_delay_enabled" hide-details @input="disableStuartDelay")
                checkbox-input.mt-0(:value="isPriority" label="labels.is_priority" hide-details @input="togglePriority")
            .d-flex.align-center.flex-wrap.mb-1.mb-1(v-if="hasDeliveryRuleOverrides")
                v-tooltip(bottom)
                    template(v-slot:activator="{ on }")
                        .text-body-2.error--text(v-on="on") {{$t('labels.has_delivery_rule_overrides')}}
                    pre.text-body-2 {{ JSON.stringify(deliveryRuleOverrides, null, 2) }}
                edit-button.ml-auto(
                    :tooltip="$t('actions.remove_overrides')"
                    :loading="deliveryRuleOverridesRemoving"
                    color="error"
                    icon='cancel'
                    @execute="removeDeliveryRuleOverrides")
            .d-flex.flex-column-reverse
                .provider-job(v-for="(data, index) in providerJobs" :key="`providerJob-${index}`")
                    v-row(no-gutters)
                        v-col.py-2.pr-2(cols="5")
                            .text-body-1.g-26--text.line-1 {{ getName(data) }}
                                v-chip.px-2.ml-2(x-small color="info" v-if="!data.deliveryJob.disableAutoCancel") Auto-cancel
                                v-icon.ml-1(v-if="data.canBeStacked" small color="primary") layers
                            .text-body-2.text-ellipsis(v-if="data.deliveryUid") JID: {{data.deliveryUid }}
                            .text-body-2.text-ellipsis(v-if="data.persistentData['stuart_delivery_id']") PID: {{data.persistentData['stuart_delivery_id'] }}
                        v-col.py-2.pr-2(cols)
                            .d-flex
                                v-icon(:color="deliveryJobStatusIconColor(data)") location_pin
                                div
                                    .text-body-2
                                        template(v-if="data.status === 'in_progress' && data.courierStatus") {{ $t(`courier_status.${data.courierStatus}`)}}
                                        template(v-else) {{ $t(`delivery_job_status.${data.status}`)}}
                                    .text-body-2 {{data.updatedAt | transformDate}}
                                    .text-body-2(v-if="needDriverName(data.courierStatus)" :class="driverNameColor(data.courierStatus)")
                                        template(v-if="data.deliveryJob.driverName") {{ data.deliveryJob.driverName }}
                                        template(v-else) {{ $t(`labels.unkown_driver`) }}
                        v-col.py-2(
                            cols="auto"
                            v-if="data.canBeCancelled && data.status !== 'cancelled'")
                            edit-button(
                                :tooltip="$t('labels.cancel_provider_job')"
                                :loading="cancelProviderLoading.includes(data.id)"
                                color="error"
                                :icon="data.cancelIsFree ? 'cancel' : 'monetization_on'"
                                @execute="cancelProviderJob(data.id)")
                        v-col.py-2(
                            cols="auto"
                            v-if="data.status === 'queued' && ['onfleet', 'onfleet_pedivan'].includes(data.deliveryMethod)")
                            edit-button(
                                :tooltip="$t('labels.send_provider_job')"
                                :loading="sendProviderLoading.includes(data.id)"
                                icon='send'
                                @execute="sendProviderJob(data.id)")
                    v-divider.my-0.py-0(v-if="index")
            div(v-if="deliveryProofs.length")
                v-divider.my-0.py-0.mb-2
                .text-h6.font-weight-bold.mb-5 {{ $t('labels.proof_of_delivery') }}
                .d-flex.justify-space-around.pb-3
                    a.d-block(v-for="proof in deliveryProofs", :key="proof", :href="proof", target="_blank")
                        img.proof-of-delivery(:src="proof", class="")
                v-btn.mt-2(text, :loading="proofRefreshing", color="info", @click="refreshDeliveryImages") {{ $t('labels.proof_update_images') }}
</template>

<script>
import { mapGetters } from 'vuex'
import deliveryType from '@/store/type/deliveryType'
import OrdersApiClient from '@/api/RestApi/OrdersApiClient'
import DeliveriesApiClient from '@/api/RestApi/DeliveriesApiClient'
import RequestDriverModal from '@/components/pages/orders/tabs/general/dialog/RequestDriverModal.vue'
import AddressMultipleMapInput from '@/components/form/Inputs/google/AddressMultipleMapInput.vue'
import EditButton from '@/components/table/buttons/EditButton.vue'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput.vue'
import { ROLE_ADMIN } from '@/enum/roles'
import authType from '@/store/type/authType'

export default {
    components: {
        EditButton,
        AddressMultipleMapInput,
        RequestDriverModal,
        CheckboxInput,
    },
    props: {
        model: Object,
    },
    data() {
        return {
            cancelProviderLoading: [],
            sendProviderLoading: [],
            forceFinishLoading: false,
            forceFinishSuccessful: false,
            stuartDelayLoading: false,
            deliveryRuleOverridesRemoving: false,
            now: null,
            interval: null,
            proofRefreshing: false,
        }
    },
    computed: {
        ...mapGetters({
            providerJobs: deliveryType.getters.PROVIDER_JOBS,
            deliveryJob: deliveryType.getters.DELIVERY_JOB,
            userRoles: authType.getters.GET_ROLES,
        }),
        isSuperAdmin() {
            return this.userRoles.indexOf(ROLE_ADMIN) !== -1
        },
        fullView() {
            return !this.isPicking && !this.isDelivering
        },
        isPicking() {
            return ['picking', 'almost_picking', 'waiting_at_pickup'].includes(
                this.deliveryJob?.courierStatus
            )
        },
        isStuartDelayEnabled() {
            return !this.deliveryJob.disableStuartDelay
        },
        isStuartDelayRelevant() {
            const relevancy = this.providerJobs.some((item) =>
                ['onfleet', 'onfleet_pedivan'].includes(item.deliveryMethod)
            )
            return relevancy
        },
        isDelivering() {
            return [
                'delivering',
                'almost_delivering',
                'waiting_at_dropoff',
            ].includes(this.deliveryJob?.courierStatus)
        },
        visibleCourierIcon() {
            return this.isDelivering || this.isPicking
        },
        isPriority() {
            return !!this.deliveryJob.isPriority
        },
        markers() {
            return [
                {
                    address:
                        this.deliveryJob?.pickupAddress?.full_address || '',
                    icon: 'restaurant',
                    lat: this.deliveryJob?.pickupAddress?.latitude || null,
                    lng: this.deliveryJob?.pickupAddress?.longitude || null,
                    zindex: 1,
                    focused: this.isPicking || this.fullView,
                    title: this.$t('labels.position_restaurant'),
                },
                {
                    address: null,
                    icon: 'courier',
                    lat: this.visibleCourierIcon
                        ? this.deliveryJob.courierLat
                        : undefined,
                    lng: this.visibleCourierIcon
                        ? this.deliveryJob.courierLong
                        : undefined,
                    zindex: 3,
                    focused: this.visibleCourierIcon,
                    title: this.$t('labels.position_courier'),
                },
                {
                    address:
                        this.deliveryJob?.dropoffAddress?.full_address || '',
                    icon: 'dropoff',
                    lat: this.deliveryJob?.dropoffAddress?.latitude || null,
                    lng: this.deliveryJob?.dropoffAddress?.longitude || null,
                    zindex: 2,
                    focused: this.isDelivering || this.fullView,
                    title: this.$t('labels.position_dropoff'),
                },
            ]
            //lat: 51.5091943, lng: -0.0783677
            //lat:51.51914240000001, lng: -0.1503294
        },
        etaToPickup() {
            return this.deliveryJob?.etaToPickup && this.now
                ? Math.trunc(
                      this.$date
                          .duration(
                              this.$date(
                                  this.deliveryJob?.etaToPickup
                              ).valueOf() - this.now.valueOf()
                          )
                          .asMinutes()
                  )
                : undefined
        },
        etaToDropoff() {
            return this.deliveryJob?.etaToDropoff && this.now
                ? Math.trunc(
                      this.$date
                          .duration(
                              this.$date(
                                  this.deliveryJob?.etaToDropoff
                              ).valueOf() - this.now.valueOf()
                          )
                          .asMinutes()
                  )
                : undefined
        },
        pickupTime() {
            return this.$options.filters.transformDate(
                this.deliveryJob.pickupTime,
                0,
                true
            )
        },
        deliveryProofs() {
            return this.deliveryJob?.proofOfDeliveries || []
        },
        showEtaToPickup() {
            return ['picking', 'almost_picking'].includes(
                this.deliveryJob.courierStatus
            )
        },
        showEtaToDropoff() {
            return ['delivering', 'almost_delivering'].includes(
                this.deliveryJob.courierStatus
            )
        },
        deliveryRuleOverrides() {
            return this.deliveryJob?.deliveryRuleOverrides
        },
        hasDeliveryRuleOverrides() {
            return this.deliveryRuleOverrides !== null
        },
    },

    watch: {
        deliveryJob: {
            immediate: true,
            handler(deliveryJob) {
                if (
                    ((deliveryJob?.etaToDropoff && this.showEtaToDropoff) ||
                        (deliveryJob?.etaToPickup && this.showEtaToPickup)) &&
                    !this.now
                ) {
                    this.$set(this, 'now', this.$date())
                    if (!this.interval) {
                        this.interval = setInterval(() => {
                            if (this.deliveryJob.courierStatus === 'finished') {
                                clearInterval(this.interval)
                                this.interval = null
                            } else this.$set(this, 'now', this.$date())
                        }, 10000)
                    }
                }
            },
        },
    },

    methods: {
        cancelProviderJob(id) {
            this.cancelProviderLoading.push(id)
            return DeliveriesApiClient.cancelDeliveryProviderJob(id)
                .then((res) => {
                    if (res.success)
                        this.$store.dispatch(
                            deliveryType.actions.CANCEL_PROVIDER_JOB,
                            id
                        )
                })
                .finally(() => {
                    let index = this.cancelProviderLoading.indexOf(id)
                    if (index >= 0) this.cancelProviderLoading.splice(index, 1)
                })
        },
        disableStuartDelay() {
            this.stuartDelayLoading = true
            DeliveriesApiClient.disableStuartDelay(this.deliveryJob.id).finally(
                () => {
                    this.stuartDelayLoading = false
                }
            )
        },
        sendProviderJob(id) {
            this.sendProviderLoading.push(id)
            DeliveriesApiClient.sendDeliveryProviderJob(id).finally(() => {
                let index = this.sendProviderLoading.indexOf(id)
                if (index >= 0) this.sendProviderLoading.splice(index, 1)
            })
        },
        forceFinishDeliveryJob() {
            this.forceFinishLoading = true
            DeliveriesApiClient.forceFinishDeliveryJob(this.deliveryJob.id)
                .then((res) => {
                    if (res.success) this.forceFinishSuccessful = true
                })
                .finally(() => {
                    this.forceFinishLoading = false
                })
        },
        getName(providerJob) {
            let name = providerJob.deliveryMethod
            if (providerJob?.persistentData?.large) {
                name = 'stuart_large'
            }
            if (providerJob?.persistentData?.xlarge) {
                name = 'stuart_xlarge'
            }
            return this.$t(`enums.delivery_methods.${name}`)
        },
        refreshDeliveryImages() {
            const id = this.model?.id
            if (!id) return
            this.proofRefreshing = true
            OrdersApiClient.updateProofOfDelivery(id).finally(() => {
                this.proofRefreshing = false
            })
        },
        removeDeliveryRuleOverrides() {
            this.deliveryRuleOverridesRemoving = true
            DeliveriesApiClient.removeDeliveryRuleOverrides(this.deliveryJob.id)
                .then(() => {
                    DeliveriesApiClient.getDeliveryJob(
                        this.deliveryJob.id
                    ).finally(() => {
                        this.deliveryRuleOverridesRemoving = false
                    })
                })
                .catch(() => {
                    this.deliveryRuleOverridesRemoving = false
                })
        },
        deliveryJobStatusIconColor(job) {
            let status =
                job.status === 'in_progress'
                    ? job.courierStatus || 'searching'
                    : job.status

            switch (status) {
                case 'searching':
                    return 'info'
                case 'picking':
                case 'almost_picking':
                case 'waiting_at_pickup':
                    return 'yellow'
                case 'delivering':
                case 'almost_delivering':
                case 'waiting_at_dropoff':
                    return 'purple darken-3'
                case 'finished':
                case 'delivered':
                    return 'success'
                case 'cancelled':
                case 'error':
                    return 'error'
                default:
                    // case 'initialized':
                    // case 'queued':
                    // case 'closed':
                    // case 'merged':
                    return 'g-cc'
            }
        },
        needDriverName(status) {
            return (
                [
                    'picking',
                    'almost_picking',
                    'waiting_at_pickup',
                    'delivering',
                    'almost_delivering',
                    'waiting_at_dropoff',
                    'finished',
                    'delivered',
                    'cancelled',
                ].indexOf(status) > -1
            )
        },
        driverNameColor(status) {
            return (
                {
                    finished: 'grey--text',
                    delivered: 'grey--text',
                    cancelled: 'grey--text',
                }[status] ?? 'info--text'
            )
        },
        togglePriority() {
            DeliveriesApiClient.setPriority(
                this.deliveryJob.id,
                !this.deliveryJob.isPriority
            )
        },
    },
}
</script>

<style>
img.proof-of-delivery {
    display: block;
    max-width: 100%;
    max-height: 30vh;
}
</style>
