var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tab-item',{key:"history_tab",attrs:{"transition":false,"reverse-transition":false}},[(_vm.id)?_c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters,"fixed-filters":_vm.fixedFilters},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{staticClass:"order-history-table",attrs:{"items":items,"headers":_vm.headers,"filters":_vm.filters},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("transformDate")(item.createdAt))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.createdAt))])])]}},{key:"item.payload",fn:function(ref){
var item = ref.item;
return [_vm._l((item.payload),function(values,key){return _c('div',{key:key,staticClass:"d-flex justify-space-between line-separator"},[_c('code',{staticClass:"text-break"},[_vm._v(_vm._s(key))]),_c('code',{staticClass:"text-break"},[_c('diff-value',{attrs:{"value":values}})],1)])}),(item.payload.length === 0)?_c('div',[_vm._v("--")]):_vm._e()]}}],null,true)})]}}],null,false,2144718299)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }